import { useEffect } from 'react'
import { SalePageSection } from 'src/generated/graphql'
import Showcase from '@molecules/Showcase/Showcase'
import { isClient } from '@utils/env'
import ecommerceAnalytics from '@lib/gtm/events/ecommerce'
import segmentAnalytics from '@lib/segment/analytics'
import { useGlobalContext } from '@config/contexts'
import { Box } from '@lib/UIComponents'
import { UIStyles } from '@lib/UIComponents/types'

type Props = {
  pageSection: SalePageSection
  pageSectionPosition: number
  sx?: UIStyles
}

const SalePageSectionComponent = ({
  pageSection,
  pageSectionPosition,
  sx = {}
}: Props) => {
  const { title, sales, indexUrl } = pageSection
  const { page, publisher } = useGlobalContext()

  const items = sales.map((sale) => ({
    ...sale,
    priceInCents: sale.retailPriceInCents,
    priceWithDiscountInCents: sale.priceInCents
  }))

  useEffect(() => {
    /* istanbul ignore else */
    if (isClient()) {
      ecommerceAnalytics.trackImpression(sales, `module ${title}`)
    }
  }, [sales, title])

  return (
    <Box sx={{ mt: 8, ...sx }}>
      <Showcase
        as="h2"
        imageId="fourColumnImage"
        title={title}
        titleUrl={indexUrl}
        items={items}
        withAnalytics
        pageSectionPosition={pageSectionPosition}
        onClickItem={(item, itemIndex) => {
          segmentAnalytics.trackCarousels({
            pageSection: title,
            itemPosition: itemIndex,
            page,
            pageSectionPosition,
            item,
            publisherId: publisher?.databaseId as number
          })
        }}
      />
    </Box>
  )
}

export default SalePageSectionComponent
