import { useRouter } from 'next/router'
import { mergeDeepRight } from 'ramda'

import Picture from '@atoms/UIPicture/Picture'
import Link from '@atoms/UILink/Link'
import { usePublisherContext } from '@concepts/Publisher/store/context'
import { Box, Text } from '@lib/UIComponents'
import segmentAnalytics from '@lib/segment/analytics'

export enum CardSize {
  BIG = 'big',
  MEDIUM = 'medium',
  SMALL = 'small'
}

type InlineCardAnalytics = {
  position: number
  module: string
  trackClick: Function | null
}

export type InlineCardItem = {
  id: number
  imgUrl: string
  title: string
  slug: string
  date?: string
}

type InlineCardProps = {
  item: InlineCardItem
  analytics: InlineCardAnalytics
  size?: CardSize
}

const getStyles = (size: CardSize) => {
  const defaultStyles = {
    picture: {
      display: 'flex',
      width: '100%',
      height: ['205px', '149px', '180px', '254px'],
      objectFit: 'contain',
      mb: [3, 5],
      img: {
        borderRadius: 'default'
      }
    },
    link: {
      display: 'flex',
      flexDirection: 'column',
      '&:hover': {
        textDecoration: 'none'
      }
    },
    title: {
      fontSize: [2, 2, 2, 3],
      mb: 3,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      WebkitLineClamp: '3',
      fontWeight: 'bold'
    },
    date: {
      fontSize: ['14px', '14px'],
      color: 'grays.600',
      display: 'block'
    }
  }

  const mediumStyles = {
    item: {
      mb: 4
    },
    link: {
      flexDirection: 'row',
      alignItems: 'center'
    },
    title: {
      mb: 0,
      fontSize: 1,
      lineHeight: ['24px'],
      flex: 1
    },
    picture: {
      flex: ['none', 1, 'none'],
      width: 'auto',
      mr: 5,
      mb: 0,
      height: '108px',
      img: {
        mr: 0
      }
    },
    date: {
      display: 'none'
    }
  }

  const smallStyles = {
    item: {
      mb: 4
    },
    link: {
      flexDirection: 'row'
    },
    title: {
      mb: 0,
      fontSize: 1,
      lineHeight: ['24px'],
      flex: 1
    },
    picture: {
      mr: 5,
      mb: 0,
      height: '77px',
      width: '93px',
      img: {
        mr: 0
      }
    },
    date: {
      display: 'none'
    }
  }

  switch (size) {
    case 'small':
      return mergeDeepRight(defaultStyles, smallStyles)

    case 'medium':
      return mergeDeepRight(defaultStyles, mediumStyles)

    default:
      return defaultStyles
  }
}

const InlineCard = ({
  item,
  analytics,
  size = CardSize.BIG
}: InlineCardProps) => {
  const { imgUrl, title, date, slug } = item
  const styles = getStyles(size)
  const { asPath } = useRouter()
  const { hostname, databaseId } = usePublisherContext()

  const segAnalytics = {
    moduleName: analytics.module,
    productName: item.title,
    currentUrl: `${hostname}${asPath}`,
    destinationUrl: `${hostname}${item.slug}`,
    publisherId: databaseId as number
  }

  const trackAnalytics = () => {
    if (analytics.trackClick) {
      analytics.trackClick(item, analytics.position, analytics.module)
    }
    segmentAnalytics.homepageModule(segAnalytics)
  }

  return (
    <Box as="article">
      <Link to={slug} onClick={trackAnalytics} sx={styles.link}>
        <Picture src={imgUrl} alt={title} sx={styles.picture} />

        <Text as="strong" sx={styles.title}>
          {title}
        </Text>

        <Text as="p" sx={styles.date}>
          {date}
        </Text>
      </Link>
    </Box>
  )
}

export default InlineCard
